// const { $ } = require("protractor");
$(function() {
    // add_ladda();

    
});





// var addressBarSize = parseFloat(getComputedStyle(document.documentElement).perspective) - document.documentElement.clientHeight
// console.log(getComputedStyle(document.documentElement).perspective);

function drop(e, id) {
    $('#' + id).children(".dropdown-menu").toggle();
    e.stopPropagation();

}

function add_ladda() {
    $('.btn_ladda').append("<i class='icon_ladda fa-spinner fa fa-spin'></i>");
}

function laddaStart(identificador) {
    var scope = $(identificador);
    if (scope.find('.icon_ladda').length == 0) {
        scope.append("<i class='icon_ladda fa-spinner fa fa-spin'></i>")
    }
    scope.attr('data-ladda', '');
}

function laddaStop(identificador) {
    $(identificador).removeAttr('data-ladda');
}

$(document).on('click', '.popoverInfo', function() {

    $(this).popover('toggle')

    let id = $(this).attr('aria-describedby');

    if (id) {

        let visible = $('#' + id).hasClass('show');

        if (!visible) {
            $(this).popover('hide');
        } else {
            $(this).popover('show');

        }
    } else {
        $(this).popover('show');

    }

})

$(function() {
    $('[data-toggle="popover"]').popover();
    // $('[data-toggle="popover"]').popover({
    //     trigger: 'focus'
    // })
})

$('.dropdown').on('show.bs.dropdown', function() {
    $('body').append($('.dropdown').css({
        position: 'absolute',
        left: $('.dropdown').offset().left,
        top: $('.dropdown').offset().top
    }).detach());
});